import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aside from '../components/Aside';
import ClipLoader from "react-spinners/ClipLoader";


const Products = () => {

	const [products, setProducts] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		populate()
	}, [])

    const populate = () => {
		setLoading(true)
        const endpoint = process.env.REACT_APP_BASE_URL + '/products/all';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                setLoading(false)
            }else{
                setProducts(response.data.filter(item => parseInt(item.prdt_id) >= 42313485))
                setLoading(false)
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

 	return (
    <>
    {/* <div className="preloader">
		<div className="lds-ripple">
			<div className="lds-pos"></div>
			<div className="lds-pos"></div>
		</div>
    </div> */}
  
    <div
		id="main-wrapper"
		data-layout="vertical"
		data-navbarbg="skin5"
		data-sidebartype="full"
		data-sidebar-position="absolute"
		data-header-position="absolute"
		data-boxed-layout="full"
    >
		<Header />
		<Aside page="products"/>
  	
		<div className="page-wrapper">
			<div className="page-breadcrumb">
				<div className="row">
					<div className="col-12 d-flex no-block align-items-center">
					<h4 className="page-title">Products</h4>
					<div className="ms-auto text-end">
						<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">
							Products
							</li>
						</ol>
						</nav>
					</div>
					</div>
				</div>
			</div>
		
			<div className="container-fluid">
				<div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Products</h5>
                            {
							loading ? 
                                <ClipLoader
                                    color="fff"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : 
                                <div className="table-responsive">
                                    <table
                                    id="products-report"
                                    className="table table-striped table-bordered"
                                    >
                                        <thead style={{ fontWeight:'bold' }}>
                                            <tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Billing</th>
                                                <th>Status</th>
                                               
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                products.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.prdt_id}</td>
                                                        <td>{item.prdt_name}</td>
                                                        <td>{item.prdt_code}</td>
                                                        <td>{item.billing_cycle}</td>
														{item.status === 'true' ? 
                                                        	<td>Active</td>
														:
															<td>Inactive</td>
														}
                                                       
                                                    </tr>
                                                ))
                                            }                            
                                        </tbody>
                                        <tfoot>
											<tr>
                                                <th>Id</th>
                                                <th>Name</th>
                                                <th>Code</th>
                                                <th>Billing</th>
                                                <th>Status</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
	</>
  	);
}

export default Products;
