import React from 'react';
import { Outlet, Navigate } from "react-router-dom";

const useAuth = () => {
    const user = localStorage.getItem('backend_vxs_user');

    if(!user)
        return false;
    else        
        return true;
}

const ProtectedRoutes = () => {
    const isAuth = useAuth()
    return isAuth ? <Outlet /> : <Navigate to="/login" />;
}



export default ProtectedRoutes;