import React, {useEffect, useState} from 'react';

const Aside = ({page}) => {
  
    const [role, setRole] = useState('')
    useEffect(() => {
        const user = localStorage.getItem('backend_vxs_user');
        if(user){
            const info = JSON.parse(user);
            setRole(info.role)
        }
    }, [])
    
    return (
        <aside className="left-sidebar" data-sidebarbg="skin5">
            <div className="scroll-sidebar">
                <nav className="sidebar-nav">
                    <ul id="sidebarnav" className="pt-4">
                        {
                        page === "" ? 
                        <li className="sidebar-item selected">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link active" href="/">
                                    <i className="mdi mdi-view-dashboard">
                                    </i><span className="hide-menu">Dashboard</span></a>
                            </li>
                            :
                            <li className="sidebar-item">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link" href="/">
                                    <i className="mdi mdi-view-dashboard">
                                    </i><span className="hide-menu">Dashboard</span></a>
                            </li>
                        }
                        {
                        role === 'admin' ? 
                            <>
                            {page === "customers" ?
                            <li className="sidebar-item selected">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link active" href="/customers"><i className="fas fa-users"></i><span className="hide-menu">Customers</span>
                                </a>
                            </li>
                            :
                            <li className="sidebar-item">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link" href="/customers"><i className="fas fa-users"></i><span className="hide-menu">Customers</span>
                                </a>
                            </li>
                            }
                      
                            {page === "subscriptions" ?
                            <li className="sidebar-item selected">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link active" href="/subscriptions"><i className="fas fa-money-bill-alt"></i><span className="hide-menu">Subscriptions</span>
                                </a>
                            </li>
                            :
                            <li className="sidebar-item">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link" href="/subscriptions"><i className="fas fa-money-bill-alt"></i><span className="hide-menu">Subscriptions</span>
                                </a>
                            </li>
                            }

                            {page === "products" ?
                            <li className="sidebar-item selected">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link active" href="/products"><i className="fab fa-product-hunt"></i><span className="hide-menu">Products</span>
                                </a>
                            </li>
                            :
                            <li className="sidebar-item">
                                <a
                                className="sidebar-link waves-effect waves-dark sidebar-link" href="/products"><i className="fab fa-product-hunt"></i><span className="hide-menu">Products</span>
                                </a>
                            </li>
                            }
                        </>
                        : ''
                        }
                        {page === "support" ?
                        <li className="sidebar-item selected">
                            <a
                            className="sidebar-link waves-effect waves-dark sidebar-link active" href="/support"><i className="fas fa-circle-question"></i><span className="hide-menu">Support</span>
                            </a>
                        </li>
                        :
                        <li className="sidebar-item">
                            <a
                            className="sidebar-link waves-effect waves-dark sidebar-link" href="/support"><i className="fas fa-circle-question"></i><span className="hide-menu">Support</span>
                            </a>
                        </li>
                        }
                    </ul>
                </nav>
            </div>
        </aside>
    )
}

export default Aside