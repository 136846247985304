import React  from 'react';
import Home from './Pages/Home';
import Customers from './Pages/Customers';
import Users from './Pages/Users';
import Subscriptions from './Pages/Subscriptions';
import Products from './Pages/Products';
import Support from './Pages/Support';
import { ContextProvider } from './components/Context';
import Login from './Pages/Login';
import ProtectedRoutes from './components/ProtectedRoutes';


import {
    BrowserRouter as Router,
    Route,
    Routes,
} from "react-router-dom";


function App() {

	return (
		<ContextProvider>
		<Router>
			<Routes>
				<Route element={<ProtectedRoutes/>}>
					<Route path = "/" element={<Home/>} /> 
					<Route path = "/customers" element={<Customers/>} /> 
					<Route path = "/customers/:id/users" element={<Users/>} /> 
					<Route path = "/subscriptions" element={<Subscriptions/>} /> 
					<Route path = "/products" element={<Products/>} />
					<Route path = "/support" element={<Support/>} />
				</Route>
				<Route path = "/login" element={<Login/>} />
			</Routes>
		</Router>
		</ContextProvider>
	);
}

export default App;
