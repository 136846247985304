import React, {useEffect} from 'react'
import LoginForm from '../components/LoginForm'

const Login = () => {

	
	useEffect(() => {
		const userInfo = localStorage.getItem('vxs_user');
		if(userInfo && userInfo !== "undefined"){
			
		}else{
			
		}
	})
  
	return (
		<div>
			{/* <Header/> */}
			<LoginForm />
			{/* <Footer /> */}
		</div>
	)
}

export default Login