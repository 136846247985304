import React, {useEffect, useState} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aside from '../components/Aside';
import ClipLoader from "react-spinners/ClipLoader";
import {useContext} from 'react'
import {Context} from '../components/Context';

const Support = () => {
    const {nbOpenedTickets, setNbOpenedTickets} = useContext(Context);
    const [list, setList] = useState([])
    const [loading, setLoading] = useState(false)
    const [showAddUser, setShowAddUser] = useState(false)
    const [answer, setAnswer] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [updateNumber, setUpdateNumber] = useState(false)
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [id, setId] = useState('')
 
    useEffect(() => {

        setLoading(true)
        populate()
    }, [])

    const handleClosePopup = () => {
		setShowAddUser(false)
	}


    const populate = () => {
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/lists';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                setLoading(false)
            }else{
                setList(response.data)
                setLoading(false)
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }


    const addAnswer = (id, email, firstname, lastname, subject, update) => {
        setId(id)
        setEmail(email)
        console.log(firstname + ' ' + lastname)
        setFirstname(firstname)
        setLastname(lastname)
        setShowAddUser(true)
        setSubject(subject)
        setAnswer('')
        setUpdateNumber(update)
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/ticket';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY, id : id})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                
            }else{
               setAnswer(response.data[0].answer)
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });

    }

    const handleAnswer = (e) => {
        setAnswer(e.target.value)
    }

    const saveAnswer = (e) => {
        e.preventDefault()
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/add/answer';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY, id : id, email : email, firstname : firstname, lastname : lastname, subject : subject, answer : answer})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                
            }else{
                populate()
                setAnswer('')
                setShowAddUser(false)
                if(updateNumber){
                    const nb = nbOpenedTickets > 0 ? nbOpenedTickets - 1 : 0
                    setNbOpenedTickets(nb)
                }
            }
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }
    
 	return (
    <>
    {/* <div className="preloader">
		<div className="lds-ripple">
			<div className="lds-pos"></div>
			<div className="lds-pos"></div>
		</div>
    </div> */}
  
    <div
		id="main-wrapper"
		data-layout="vertical"
		data-navbarbg="skin5"
		data-sidebartype="full"
		data-sidebar-position="absolute"
		data-header-position="absolute"
		data-boxed-layout="full"
    >
		<Header />
		<Aside page="support"/>
  	
		<div className="page-wrapper">
			<div className="page-breadcrumb">
				<div className="row">
					<div className="col-12 d-flex no-block align-items-center">
					<h4 className="page-title">Support</h4>
					<div className="ms-auto text-end">
						<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">
							Support
							</li>
						</ol>
						</nav>
					</div>
					</div>
				</div>
			</div>

            <div className="container-fluid">
				<div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Support</h5>
                            {
							loading ? 
                                <ClipLoader
                                    color="fff"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : 
                                <div className="table-responsive">
                                    <table
                                    id="support_report"
                                    className="table table-striped table-bordered"
                                    >
                                        <thead style={{ fontWeight:'bold' }}>
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Answer</th>
                                                <th>Last Update</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                list.map((item, i) => (
                                                    <tr key={i}>
                                                        <td><b>{item.firstname.charAt(0).toUpperCase() + item.firstname.slice(1) + ' ' + item.lastname.charAt(0).toUpperCase() + item.lastname.slice(1)}</b></td>
                                                        <td>{item.category}</td>
                                                        <td>{item.subject}</td>
                                                        <td>{item.message}</td>
                                                        <td>{item.answer}</td>
                                                        <td>{item.lastupdate}</td>
                                                        {
                                                        item.opened === 'false' ? 
                                                        <td>
                                                             <button
                                                                type="button"
                                                                className="btn btn-success btn-sm text-white"
                                                                onClick={() => addAnswer(item.id, item.email, item.firstname, item.lastname, item.subject, true)}
                                                                >
                                                                    Answer
                                                            </button>
                                                        </td>
                                                        :
                                                        <td>
                                                            <button
                                                                type="button"
                                                                className="btn btn-cyan btn-sm text-white"
                                                                onClick={() => addAnswer(item.id, item.email, item.firstname, item.lastname, item.subject, false)}
                                                                >
                                                                    Edit
                                                            </button>
                                                        </td>
}
                                                    </tr>
                                                ))
                                            }                            
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Name</th>
                                                <th>Category</th>
                                                <th>Subject</th>
                                                <th>Message</th>
                                                <th>Answer</th>
                                                <th>Last Update</th>
                                                <th></th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
				</div>
			</div>
        <div>
    </div>
    </div>
        {showAddUser ? 
            <>
            <div className="login-overlay overlay"></div>
            <div id="loginformplan" style={{ minHeight:'unset' }}>
				<div className="content">
                    <div className="login-close" onClick={handleClosePopup}></div>
                    <div className="title">
                        <h4 className="box-title mb-3">Add Answer</h4>
                    </div>
                   
                    <div className="row">
                        <div className="col-12">
                            <form>
                                <div className="mb-3 form-group required">
                                    <label htmlFor="lastname" className="form-label">Answer</label>
                                    <textarea type="text" className="form-control" id="answer" aria-describedby="emailHelp" value={answer} onChange={(e) => handleAnswer(e)}></textarea>
                                </div>
                                <button type="submit" className="btn btn-primary text-medium btn-border-radius" onClick={(e) => saveAnswer(e)}>Save</button>
                            </form>
                        </div>
				    </div>
                </div>
			</div>
            </>
        : ''
        }
	<Footer />
	</div>
    </>
  	);
}

export default Support;
