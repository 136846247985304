import React, {useEffect, useState} from 'react'
import { useNavigate } from 'react-router-dom'
import {useContext} from 'react'
import {Context} from './Context';

const Header = () => {
	const [list, setList] = useState([])
	const [username, setUsername] = useState('')
	const {nbOpenedTickets, setNbOpenedTickets} = useContext(Context);
	const navigate = useNavigate()
	const onClickAnchor = (e) => {
		e.preventDefault();
	}

	const logout = (e) => {
		e.preventDefault()
		console.log("logout")
		setTimeout(() => {
			localStorage.removeItem('backend_vxs_user')
		}, 1000);
		navigate('/login')
	}

	useEffect(() => {

		const person = localStorage.getItem("backend_vxs_user");
        if(person){
			const info = JSON.parse(person);
			setUsername(info.username)
		}
        const endpoint = process.env.REACT_APP_BASE_URL + '/user/support/lists';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                
            }else{
                setList(response.data.filter(item => item.opened === 'false'))
				setNbOpenedTickets(list.length)
            }
        })
        .catch(err => {
            console.log(err);
           
        });
    }, [list.length, setNbOpenedTickets])

  	return (
		<header className="topbar" data-navbarbg="skin5">
			<nav className="navbar top-navbar navbar-expand-md navbar-dark">
				<div className="navbar-header" data-logobg="skin5">
			
					<a className="navbar-brand" href="/">
						{/* <b className="logo-icon ps-2">
							<img
							src="/assets/images/logo-icon.png"
							alt="homepage"
							className="light-logo"
							width="25"
							/>
						</b> */}
					
						<span className="logo-text ms-2">
							<img
							src="/assets/images/logo-white-new.png"
							alt="homepage"
							className="light-logo"
							/>
						</span>
					</a>
				
					<a
					className="nav-toggler waves-effect waves-light d-block d-md-none"
					href="/#" onClick={onClickAnchor}
					><i className="ti-menu ti-close"></i>
					</a>
				</div>
		
				<div
					className="navbar-collapse collapse"
					id="navbarSupportedContent"
					data-navbarbg="skin5"
				>
			
					<ul className="navbar-nav float-start me-auto">
						<li className="nav-item d-none d-lg-block">
							<span
							className="nav-link sidebartoggler waves-effect waves-light"
							onClick={onClickAnchor}
							data-sidebartype="mini-sidebar"
							>
								<i className="mdi mdi-menu font-24"></i>
							</span>
						</li>
					</ul>
			
					<ul className="navbar-nav float-end">
				
						<li className="nav-item dropdown" style={{ display:'none' }}>
							<span
							className="nav-link dropdown-toggle"
							onClick={onClickAnchor}
							role="button"
							data-bs-toggle="dropdown">						
							</span>
							
						</li>
				
						<li className="nav-item dropdown">
							<span
							className="nav-link dropdown-toggle waves-effect waves-dark"
							onClick={onClickAnchor}
							id="2"
							role="button"
							data-bs-toggle="dropdown"
							>
								
								{
								nbOpenedTickets > 0 ? 
								<>
									<i className="mdi mdi-bell font-24"></i>
									<span className="count bg-success">{nbOpenedTickets}</span>
								</>
								: 
								''
								}
							</span>
							<ul
							className="
								dropdown-menu dropdown-menu-end
								mailbox
								animated
								bounceInDown
							"
							aria-labelledby="2"
							>
								<ul className="list-style-none">
									{
									list.map((item, i) => 
										<li key={i}>
											<div className="" style={{paddingLeft:'10px'}} key={i}>
											
												<a href="/support" className="link border-top">
													<div className="d-flex no-block align-items-center p-10">
														<span
														className="
															btn btn-primary btn-circle
															d-flex
															align-items-center
															justify-content-center
														"><i className="mdi mdi-account fs-4"></i></span>
														<div className="ms-2">
															<h5 className="mb-0">{item.firstname + ' ' + item.lastname}</h5>
															<div className="mail-desc mb-3"> {item.message}</div>
															<div className="text-extra-small text-muted mt-10">{item.creation_date}</div>
														</div>
														
													</div>
												</a>
											</div>
										</li>
									)
									}
									
									
									
								</ul>
							</ul>
						</li>
				
						<li className="nav-item dropdown">
							<span
							className="
								nav-link
								dropdown-toggle
								text-muted
								waves-effect waves-dark
								pro-pic"
							id="navbarDropdown"
							role="button"
							data-bs-toggle="dropdown"
							>
								<img
									src="../assets/images/users/1.jpg"
									alt="user"
									className="rounded-circle"
									width="31"
								/>&nbsp;
								{username}
							</span>
							<ul
								className="dropdown-menu dropdown-menu-end user-dd animated"
								aria-labelledby="navbarDropdown"
							>
								<a className="dropdown-item" href="/#" onClick={(e) => logout(e)}><i className="fa fa-power-off me-1 ms-1"></i> Logout</a>
							</ul>
						</li>
					</ul>
				</div>
			</nav>
		</header>
  	)
}

export default Header