import React, {useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aside from '../components/Aside';


const Home = () => {

	useEffect(() => {
        window.scrollTo(0, 0)
	})

 	return (
    <>
    {/* <div className="preloader">
		<div className="lds-ripple">
			<div className="lds-pos"></div>
			<div className="lds-pos"></div>
		</div>
    </div> */}
  
    <div
		id="main-wrapper"
		data-layout="vertical"
		data-navbarbg="skin5"
		data-sidebartype="full"
		data-sidebar-position="absolute"
		data-header-position="absolute"
		data-boxed-layout="full"
    >
		<Header />
		<Aside />
  	
		<div className="page-wrapper">
			<div className="page-breadcrumb">
				<div className="row">
					<div className="col-12 d-flex no-block align-items-center">
						<h4 className="page-title">Dashboard</h4>
						<div className="ms-auto text-end">
							<nav aria-label="breadcrumb">
								<ol className="breadcrumb">
									<li className="breadcrumb-item"><a href="/">Home</a></li>
									
								</ol>
							</nav>
						</div>
					</div>
				</div>
			</div>
		
			<div className="container-fluid">
				<div className="row">
					
						<div className="col-md-4 col-lg-4 col-xlg-3">
							
							<div className="card card-hover">
								<div className="box bg-cyan text-center">
								<h1 className="font-light text-white">
									<i className="fas fa-users"></i>
								</h1>
								<h6 className="text-white">Customers</h6>
								</div>
							</div>
						</div>
					
					
						<div className="col-md-4 col-lg-4 col-xlg-3">
							<div className="card card-hover">
								<div className="box bg-cyan text-center">
								<h1 className="font-light text-white">
									<i className="fas fa-money-bill-alt"></i>
								</h1>
								<h6 className="text-white">Subscriptions</h6>
								</div>
							</div>
						</div>
					
				
					<div className="col-md-4 col-lg-4 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-success text-center">
							<h1 className="font-light text-white">
								<i className="fab fa-product-hunt"></i>
							</h1>
							<h6 className="text-white">Products</h6>
							</div>
						</div>
					</div>
					{/* 
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-warning text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-collage"></i>
							</h1>
							<h6 className="text-white">Widgets</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-danger text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-border-outside"></i>
							</h1>
							<h6 className="text-white">Tables</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-info text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-arrow-all"></i>
							</h1>
							<h6 className="text-white">Full Width</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-4 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-danger text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-receipt"></i>
							</h1>
							<h6 className="text-white">Forms</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-info text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-relative-scale"></i>
							</h1>
							<h6 className="text-white">Buttons</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-cyan text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-pencil"></i>
							</h1>
							<h6 className="text-white">Elements</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-success text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-calendar-check"></i>
							</h1>
							<h6 className="text-white">Calnedar</h6>
							</div>
						</div>
					</div>
				
					<div className="col-md-6 col-lg-2 col-xlg-3">
						<div className="card card-hover">
							<div className="box bg-warning text-center">
							<h1 className="font-light text-white">
								<i className="mdi mdi-alert"></i>
							</h1>
							<h6 className="text-white">Errors</h6>
							</div>
						</div>
					</div> */}
				</div>
			</div>
		</div>
		<Footer />
	</div>
	</>
  	);
}

export default Home;
