import React, {useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const LoginForm = () => {
    const navigate = useNavigate()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    

    const handleUsername = (event) => {
        setUsername(event.target.value)
        if (event.target.value.length < 5 ) {
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
        if (event.target.value.length < 5 ){
            event.target.classList.remove('is-valid');
            event.target.classList.add('is-invalid');
        }
        else{
            event.target.classList.remove('is-invalid'); 
            event.target.classList.add('is-valid'); 
        }
    }

    const [errorMessage, setErrorMessage] = useState('')
    const [successMessage, setSuccessMessage] = useState('')

    const showError = {
        display : (errorMessage !== '') ? "block" : "none"
    }

    const showSuccess = {
        display : (successMessage !== '') ? "block" : "none"
    }
   
    const handleSubmit = (e) => {
        e.preventDefault();
        setErrorMessage('')
        setSuccessMessage('')
        
        const data = {
            username : username,
            password : password,
            key_id : process.env.REACT_APP_BASE_APIKEY
        };
        
        const endpoint = process.env.REACT_APP_BASE_URL + '/backend/user/login';
     
        fetch(endpoint, {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify(data)
        })
        .then(response => response.json())
        .then(response => {     
                     
            if(response.error === true){
                setErrorMessage(response.msg)
            }else{
                setTimeout(() => {
                    localStorage.setItem("backend_vxs_user", JSON.stringify(response.user));       
                    navigate('/')
                    navigate(0)
                  
                }, 1000);
            }
        })
        .catch(err => {
            console.log(err);
        });
    }

    return (
     
        <div className="auth-box">
            <div id="loginform">
                <div className="text-center pt-3 pb-3">
                    <span className="db" style={{ position:'absolute', left:'10px' }}><img src="../assets/images/logo.png" alt="logo" /></span>
                </div>
            
                <form
                className="form-horizontal mt-3 loginFormContent"
                action="index.html">
                  
                    <div className="row">
                        <div className="
                            alert alert-danger alert-dismissible
                            bg-danger
                            text-white
                            border-0
                            fade
                            show
                            " role="alert" style={showError}>
                            <strong>Error - </strong> {errorMessage}
                        </div>
                    </div>
                    <div className="row">
                        <div className="
                            alert alert-success alert-dismissible
                            bg-success
                            text-white
                            border-0
                            fade
                            show
                            " role="alert" style={showSuccess}>
                            <strong>Success - </strong> {successMessage}
                        </div>
                    </div>
                   
                    <div className="row">
                        <div className="col-12">
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text bg-info text-white h-100"
                                        id="basic-addon1"
                                        >
                                            <i className="mdi mdi-account fs-4"></i>
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control form-control-lg"
                                    placeholder="Username"
                                    aria-label="Username"
                                    aria-describedby="basic-addon1"
                                    required=""
                                    onChange={handleUsername}
                                />
                            </div>
                            <div className="input-group mb-3">
                                <div className="input-group-prepend">
                                    <span
                                        className="input-group-text bg-info text-white h-100"
                                        id="basic-addon2"
                                        >
                                            <i className="mdi mdi-lock fs-4"></i>
                                    </span>
                                </div>
                                <input
                                type="password"
                                className="form-control form-control-lg"
                                placeholder="Password"
                                autoComplete='false'
                                aria-label="Password"
                                aria-describedby="basic-addon1"
                                required=""
                                onChange={handlePassword}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row border-secondary">
                        <div className="col-12">
                            <div className="form-group">
                                <div className="pt-3">
                                    <button
                                        className="btn btn-success float-end text-white"
                                        type="submit" onClick={handleSubmit}>
                                        Login
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default LoginForm