import React, {useState, useEffect} from 'react';
import { useParams } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aside from '../components/Aside';
import ClipLoader from "react-spinners/ClipLoader";


const Users = () => {
    const {id } = useParams();
	const [customers, setUsers] = useState([])
	const [loading, setLoading] = useState(false)

	useEffect(() => {
		populate(id)
	}, [id])
	

	const populate = (id) => {
		setLoading(true)
        const endpoint = process.env.REACT_APP_BASE_URL + '/customer/myusers';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY, customer : id})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                setLoading(false)
            }else{
                setUsers(response.data)
                setLoading(false)
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

	
 	return (
    <>
    {/* <div className="preloader">
		<div className="lds-ripple">
			<div className="lds-pos"></div>
			<div className="lds-pos"></div>
		</div>
    </div> */}
  
    <div
		id="main-wrapper"
		data-layout="vertical"
		data-navbarbg="skin5"
		data-sidebartype="full"
		data-sidebar-position="absolute"
		data-header-position="absolute"
		data-boxed-layout="full"
    >
		<Header />
		<Aside page="customers"/>
  	
		<div className="page-wrapper">
			<div className="page-breadcrumb">
				<div className="row">
					<div className="col-12 d-flex no-block align-items-center">
					<h4 className="page-title">Customers</h4>
					<div className="ms-auto text-end">
						<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
							<li className="breadcrumb-item"><a href="/">Home</a></li>
                            <li className="breadcrumb-item"><a href="/customers">Customers</a></li>
							<li className="breadcrumb-item active" aria-current="page">
							Users
							</li>
						</ol>
						</nav>
					</div>
					</div>
				</div>
			</div>
		
			<div className="container-fluid">
                {
                customers.length > 0 ?
				<div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Customers List</h5>
							{
							loading ? 
							<ClipLoader
                                color="fff"
                                loading={loading}
                                size={10}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                            /> : 
                            <div className="table-responsive">
                                <table
                                id="users-report"
                                className="table table-striped table-bordered"
                                >
                                    <thead>
										<tr>
											<th>First Name</th>
											<th>Last Name</th>
											<th>Email</th>
											<th>Status</th>
											<th>Product Code</th>
                                            <th>Product Name</th>
                                        </tr>
                                    </thead>
                                    <tbody>
										{
											customers.map((item, i) => 
												<tr key={i}>
													<td>{item.firstname}</td>
													<td>{item.lastname}</td>
													<td>{item.email}</td>
													<td>{item.status}</td>
                                                    <td>{item.ipn_pcode}</td>
													<td>{item.ipn_name}</td>
												</tr>
											)
										}
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th>First Name</th>
											<th>Last Name</th>
											<th>Email</th>
											<th>Status</th>
											<th>Product Code</th>
                                            <th>Product Name</th>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
							}
                        </div>
                    </div>
				</div>
                : 'No user found'
                }
			</div>
		</div>
		<Footer />
	</div>
	</>
  	);
}

export default Users;
