import React, {useState, useEffect} from 'react';
import Header from '../components/Header';
import Footer from '../components/Footer';
import Aside from '../components/Aside';
import ClipLoader from "react-spinners/ClipLoader";


const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([])
	const [loading, setLoading] = useState(false)

    useEffect(() => {
		populate()
	}, [])

    const populate = () => {
		setLoading(true)
        const endpoint = process.env.REACT_APP_BASE_URL + '/subscriptions/all';
        fetch(endpoint, {

            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            "method": "POST",
            "body": JSON.stringify({"key_id" : process.env.REACT_APP_BASE_APIKEY})
        })
        .then(response => response.json())
        .then(response => { 
            if(response.error === true){
                setLoading(false)
            }else{
                setSubscriptions(response.data)
                setLoading(false)
            }
           
        })
        .catch(err => {
            console.log(err);
            setLoading(false)
        });
    }

 	return (

    <>
    {/* <div className="preloader">
		<div className="lds-ripple">
			<div className="lds-pos"></div>
			<div className="lds-pos"></div>
		</div>
    </div> */}
  
    <div
		id="main-wrapper"
		data-layout="vertical"
		data-navbarbg="skin5"
		data-sidebartype="full"
		data-sidebar-position="absolute"
		data-header-position="absolute"
		data-boxed-layout="full"
    >
		<Header />
		<Aside page="subscriptions"/>
  	
		<div className="page-wrapper">
			<div className="page-breadcrumb">
				<div className="row">
					<div className="col-12 d-flex no-block align-items-center">
					<h4 className="page-title">Subscriptions</h4>
					<div className="ms-auto text-end">
						<nav aria-label="breadcrumb">
						<ol className="breadcrumb">
                            <li className="breadcrumb-item"><a href="/">Home</a></li>
							<li className="breadcrumb-item active" aria-current="page">
							Subscriptions
							</li>
						</ol>
						</nav>
					</div>
					</div>
				</div>
			</div>
		
			<div className="container-fluid">
				<div className="row">
                    <div className="card">
                        <div className="card-body">
                            <h5 className="card-title">Subscriptions</h5>
                            {
							loading ? 
                                <ClipLoader
                                    color="fff"
                                    loading={loading}
                                    size={10}
                                    aria-label="Loading Spinner"
                                    data-testid="loader"
                                /> : 
                                <div className="table-responsive">
                                    <table
                                    id="subscription_config"
                                    className="table table-striped table-bordered"
                                    >
                                        <thead style={{ fontWeight:'bold' }}>
                                            <tr>
                                                <th>Email</th>
                                                <th>Purchase Email</th>
                                                <th>Licence</th>
                                                <th>Pdt</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th>Currency</th>
                                                <th>Start Date</th>
                                                <th>Expiration Date</th>
                                                <th>Order Status</th>
                                                <th>Subsription Status</th>
                                                {/* <th>Status</th> */}
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                subscriptions.map((item, i) => (
                                                    <tr key={i}>
                                                        <td>{item.email}</td>
                                                        <td>{item.purchase_email}</td>
                                                        <td>{item.subscription_reference}</td>
                                                        <td>{item.ipn_pname}</td>
                                                        <td>{item.ipn_qty}</td>
                                                        <td>{item.price}</td>
                                                        <td>{item.currency}</td>
                                                        <td>{item.startDate}</td>
                                                        <td>{item.expirationDate}</td>
                                                        <td>{item.orderstatus}</td>
                                                        <td>{item.subscription_status}</td>
                                                        {/* <td>{item.status}</td> */}
                                                    </tr>
                                                ))
                                            }                            
                                        </tbody>
                                        <tfoot>
                                            <tr>
                                                <th>Email</th>
                                                <th>Purchase Email</th>
                                                <th>Licence</th>
                                                <th>Pdt</th>
                                                <th>Qty</th>
                                                <th>Price</th>
                                                <th>Currency</th>
                                                <th>Start Date</th>
                                                <th>Expiration Date</th>
                                                <th>Order Status</th>
                                                <th>Subsription Status</th>
                                                {/* <th>Status</th> */}
                                            </tr>
                                        </tfoot>
                                    </table>
                                </div>
                            }
                        </div>
                    </div>
				</div>
			</div>
		</div>
		<Footer />
	</div>
	</>
  	);
}

export default Subscriptions;
