import React, { useState, useEffect } from 'react'


const Footer = () => {

    const[copyrightDate, setCopyrightDate] = useState()

    useEffect(() => {
        const d = new Date();
        let year = d.getFullYear();
        setCopyrightDate(year)
    },[copyrightDate])
    
    return (
  
        <footer className="footer text-center">
           <div className="trademark">
                <div className="copyright text-medium">&copy; {copyrightDate} Voxel Farm Inc. All Right Reserved. Voxel Space® is a trademark of Voxel Farm Inc.</div>
            </div>
        </footer>
    
  )
}

export default Footer