import React, { createContext } from 'react'
import { useState } from 'react';

export const Context = createContext(null);
export const ContextProvider = (props) => {
    const [nbOpenedTickets, setNbOpenedTickets] = useState(0)
    const contextValue = {nbOpenedTickets, setNbOpenedTickets}

    return (
        <Context.Provider value={contextValue}>{props.children}</Context.Provider>
    )
}
